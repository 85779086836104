.MuiClockNumber-root.Mui-disabled {
  display: none;
}

.mealContainer {
  overflow-y: scroll;
}

.queueRow {
  border-collapse: separate;
}

.queueRow > th {
  background-color: lightgray;
  border-bottom: 1px solid black !important;
  font-weight: bold !important;
}

.queueRow > th:is(:first-child) {
  border-top-left-radius: 1rem;
}

.queueRow > th:is(:last-child) {
  border-top-right-radius: 1rem;
}

.queueRow:is(:last-child) td {
  border: none !important;
}

.queueCell {
  padding: 4px 10px !important;
  min-width: 7rem;
  text-align: center !important;
  border-bottom: 1px solid darkgray !important;
}

#diningDate > * >.MuiInputBase-root.MuiOutlinedInput-root{
  height: 2.5rem !important;
}