.orderDetails {
  display: inline-block;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  color: white;
}

.detail-flex {
  display: flex;
  padding: 0 1rem;
}

.detail-info {
  height: auto;
  min-height: 30px;
  display: flex;
  align-items: center;
}

.detail-btn {
  float: right;
  margin-right: 5px;
  background-color: var(--tealColor);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid lightgray;
  padding: 6px 10px;
  box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, .25);
}

.list-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.list-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.flex-9 {
  flex: 9;
}

.flex-3 {
  flex: 3;
}

.detail-wrap {
  padding: 0 !important;
}

.detail-wrap-sub {
  padding: 0 !important;
}

.orderDetailsHeading {
  color: white;
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.orderDetailsTableBorder {
  border-top:  solid 1px;
  border-color: lightgray;
}

.orderDetailsTableTextContainer {
  display: flex;
  justify-content: center;
}

.orderDetailsContainer {
  width: 95%;
  margin-bottom: 1rem;
}

.orderDetailsUpperContainer {
  justify-content: space-between;
  display: flex;
}

.orderDetailsWrapper {
  background-color: var(--tealColor);
  border-radius: 8px;
  padding: 1rem !important;
  /* little more space on bottom so its not bunched up visually */
  margin: 1.25rem 3.125rem 1.5rem; 
  width: auto
}

.orderDetailsTableFields {
  display: inline-block;
  justify-content: flex-start;
  align-self: center;
  padding: 0px;
  color: white;
}

@media(min-width:750px)
{
  .orderDetailsContainer {
  justify-content: space-between;
  display: flex;
  }
}

@media only screen and (max-width: 746px) and (min-width: 520px)  {
  .orderDetails {
    width: 50%;
  }
}
@media(max-width: 520px)
{
  .orderDetails {
    width: 100%;
  }
}

.printOrderDetail {
  display: flex;
  align-items: center;
}

.orderDetailsTableContainer {
  display: flex;
  justify-content: center;
  
}

.orderDetailsTableWrapper {
  width: 100%;
  background-color: #D3D3D3;
}

.orderTableDetails {
  display: inline-block;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  text-align: center;
}

@media(min-width:750px)
{
  .orderDetailsTableWrapper {
    width: 80%;
    background-color: #D3D3D3;
  }
}

.MuiDataGridPro-root .MuiDataGridPro-row:hover {
  cursor: pointer;
}