#meal-btn {
  color: #000;
  background-color: #9ec6c7;
  font-weight: bold;
  border: 1px solid black;
}

#meal-btn:hover {
  background-color: #66acad;
}

#dining-btn {
  color: #FFF;
  background-color: #898486;
  font-size: .875rem;
  font-weight: bold;
  border: 1px solid black;
}

#dining-btn:hover {
  background-color: #6e696b;
}

.resident-name {
  font-size: 2rem;
  line-height: 2.5rem;
  margin-top: .125rem;
}

.workflowIcon {
  margin: 0 .5rem;
  height: .875em !important;
  width: .875em !important;
  align-self: baseline;
}

#infoImgBox {
  float: right;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  padding: .75rem;
  background-color: #3e6281;
  border-radius: var(--borderRadius);
  box-shadow: .5rem .25rem .25rem .0625rem rgba(0, 0, 0, .35) !important;
}

.infoRow {
  width: 100%;
  min-height: 2.5rem;
  height: auto !important;
  display: grid !important;
  grid-template-columns: 9rem 2fr;
}

#labelCell {
  padding: 0 0 0 1rem !important;
}

.contentCell {
  font-weight: bold !important;
}