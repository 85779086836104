#autoDialog .MuiPaper-root.MuiDialog-paper {
  max-width: calc(100% - 4rem) !important;
  min-width: calc(100% - 4rem) !important; /* need min width == max width for loading skeleton */
}

/* Need to use custom scrollbar but no element to address in JSX to attach like other views
   so we need to duplicate it here. Can expand without ID if all dialogs end up needing it */
#autoDialog .MuiPaper-root.MuiDialog-paper::-webkit-scrollbar {
	width: 10px;
	background-color: #F5F5F5;
}

#autoDialog .MuiPaper-root.MuiDialog-paper::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0,0,0,0.1);
  border: 1px solid #ccc;
}

#autoDialog .MuiPaper-root.MuiDialog-paper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(to left, #fff, #e4e4e4);
  border: 1px solid #aaa;
}

#autoDialog .MuiPaper-root.MuiDialog-paper::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

#autoDialog .MuiPaper-root.MuiDialog-paper::-webkit-scrollbar-thumb:active {
  background: linear-gradient(to left, #46b9bc, #0ae7ec);
}

#autoDialog .MuiStack-root > .MuiTextField-root {
  min-width: 125px !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: var(--tealColor) !important;
  color: white !important;
} 