.general-text {
  font-size: 1rem;
}
#info-table .MuiTableRow-root {
  height: 2.5rem;
}

#menu-table .MuiTableRow-root,
#alt-table .MuiTableRow-root,
#price-table .MuiTableRow-root {
  min-height: 2.5rem;
  height: auto !important;
}

.MuiTableCell-root {
  line-height: 1 !important;
  padding: 0 1rem !important;
  align-content: center;
}

#shortDiet {
  padding-right: 6rem !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: rgba(0, 0, 0, .14) !important;
  color: black !important;
}

.MuiCheckbox-root.Mui-checked:has(#orderCheckbox) {
  color: var(--teal-color) !important;
}

#alt-accordion.MuiAccordion-root:first-of-type {
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
}

#alt-accordion.MuiAccordion-root:last-of-type {
  border-bottom-left-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
}

.warning {
  color: #F0c63E;
  font-size: 1rem;
  margin-right: .25rem;
}

.noteTextarea {
  border: 1px solid var(--tealColor) !important;
  border-radius: .375rem !important;
  margin-bottom: .5rem !important;
}

.chargeTextarea {
  margin: 0 auto 0 1rem !important;  
}

.chargeTextarea:hover,
.chargeTextarea > div:hover {
  border: none;
}

.chargeTextarea > div > input:not([aria-invalid="true"]) {
  border: 1px solid var(--tealColor);
  border-radius: .25rem !important;
}

.chargeTextarea > div > input[aria-invalid="true"],
.chargeTextarea > div > input[aria-invalid="true"]:hover {
  border: 1px solid var(--redColor);
  border-radius: .25rem !important;
}

.chargeTextarea > div:focus-visible {
  outline: none !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused 
.MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
}

.MuiOutlinedInput-notchedOutline:focus-visible {
  outline: none;
}

.addlCharge {
  display: block;
  border: 1px solid #FDB92E !important;
  border-radius: .25rem !important;
  height: 1.5rem;
  font-size: 1rem;
  font-family: Nunito Sans, sans-serif;
  padding: .25rem .5rem;
  width: 6.125rem;
  margin-left: auto;
  margin-bottom: .25rem;
}

.addlCharge:focus {
  outline: unset !important;
}

.overCard {
  z-index: 10;
  position: relative;
  box-shadow: .125rem .25rem .25rem .0625rem rgba(0, 0, 0, .35) !important;
}

.imgBox {
  float: right;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  padding: .75rem;
  background-color: #3e6281;
  border-radius: var(--borderRadius);
  box-shadow: .5rem .25rem .25rem .0625rem rgba(0, 0, 0, .35) !important;
}

.underCard {
  width: 98%;
  margin: -1rem 0 1rem 1%;
  border: 1px solid darkgray;
}

.tableValue {
  font-weight: bold !important;
}

.noBorderCell {
  border-bottom: none !important;
}

#altTabs .MuiTab-root.Mui-selected {
  color: #000 !important;
  background-color: #e6b66d;
  border-radius: 1rem 1rem;
  transition: all .5s;
}

#altTabs .MuiTabs-flexContainer {
  justify-content: space-between;
}

#altTabs .MuiButtonBase-root.MuiTab-root {
  font-weight: bold !important;
  font-size: .75rem !important;
  color: rgba(0, 0, 0, .68)
}

#altTabs {
  width: 98%;
  background-color: #ffdeac;
  color: rgba(0, 0, 0, 0.9);
  border-radius: 2rem;
  margin: .25rem 1% 0;
}