.tableRow {
  display: grid !important;
  grid-template-columns: 10fr 42px 76px;
  width: 100%;
  border-bottom: 1px solid rgba(224,224,224,.85);
  height: auto;
  min-height: 2.5rem;
}

.tableRow > th {
  background-color: lightgray;
  border-bottom: 1px solid black !important;
  font-weight: bold !important;
}

.tableCell {
  padding: 4px 10px !important;
  min-width: 7rem;
  text-align: center !important;
  border-bottom: 1px solid darkgray !important;
}

.priceRow {
  display: grid !important;
  grid-template-columns: 11fr 1fr;
  width: 100%;
  border-bottom: 1px solid rgba(224,224,224,.85);
  height: auto;
  min-height: 2.5rem;
}